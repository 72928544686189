export const columns = [
  {
    name: "name",
    label: "Name",
    field: "name",
  },
  {
    name: "type",
    label: "Type",
    field: "type.name",
  },
  {
    name: "adapter",
    label: "Adapter",
    field: "adapter.name",
  },
  {
    name: "created",
    label: "Created",
    field: "created_at",
    component: "DateTime",
  },
];
