<template>
  <div>
    <Title :pages="pages">
      <Button status="primary" @click="promptCreate = true"> Create </Button>
    </Title>

    <ResourceTable
      url="connections"
      ref="connectionsRef"
      :columns="columns"
      :options="resourceTableOptions"
    />

    <CreateConnection
      v-if="promptCreate"
      @close="promptCreate = false"
      @created="handleCreated"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Button from "@/components/common/Button";
import Title from "@/components/common/Title";
import ResourceTable from "@/components/common/resources/Table";
import CreateConnection from "@/components/connections/Create";
import { columns } from "@/views/connections/columns";

const pages = [
  { name: "Connections", href: "connections.index", current: true },
];

export default defineComponent({
  components: {
    Title,
    Button,
    ResourceTable,
    CreateConnection,
  },

  data() {
    return {
      pages,
      columns,
      promptCreate: false,
      resourceTableOptions: {
        deleteUrl: (item) => `connections/${item._id}`,
        editRoute: (item) => ({
          name: "connections.edit",
          params: { connection: item._id },
        }),
        viewRoute: (item) => ({
          name: "connections.view",
          params: { connection: item._id },
        }),
      },
    };
  },

  methods: {
    handleCreated(connection) {
      this.promptCreate = false;
      this.$router.push({
        name: "connections.view",
        params: { connection: connection._id },
      });
    },
  },
});
</script>
